import React from "react";
import jwt_decode from "jwt-decode";
import { safeParseInt } from "../../commonutls";

export type UserData = {
  emailAddress?: string;
  role?: number;
  isLoggedIn: boolean;
  expiryDate?: Date;
  expiry: number;
};
export type AuthResult = {
  userData: UserData | undefined;
};
type jwt = {
  unique_name: string;
  role: string;
  exp: number;
};
export const useAuth = (): AuthResult => {
  const userData = React.useMemo(() => {
    const token = sessionStorage.getItem("gmba.token");
    if (token) {
      const decoded: jwt = jwt_decode(token);
      if (decoded) {
        return {
          emailAddress: decoded?.unique_name,
          role: safeParseInt(decoded?.role) || 0,
          expiryDate: new Date(decoded.exp * 1000),
          expiry: decoded.exp * 1000,
          isLoggedIn: true,
        };
      }
    }
  }, []);

  return { userData };
};
