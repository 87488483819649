import { CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./components/Router";
import { useAuth } from "./data/auth";

import { theme } from "./theme";

const AgentHome = lazy(() => import("./pages/AgentHome"));
const AgentListPage = lazy(() => import("./pages/AgentListPage"));
const CandidateHome = lazy(() => import("./pages/CandidateHome"));
const CandidateReportsPage = lazy(() => import("./pages/CandidateReports"));
const CompetitorListPage = lazy(() => import("./pages/CompetitorList"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPassword"));
const HomePage = lazy(() => import("./pages/Home"));
const Landing = lazy(() => import("./pages/Landing"));
const NotFound = lazy(() => import("./pages/NotFound"));
const PartyAgentHome = lazy(() => import("./pages/PartyAgentHome"));
const SigninPage = lazy(() => import("./pages/Signin"));
const SignoutPage = lazy(() => import("./pages/Signout"));
const SignupPage = lazy(() => import("./pages/Signup"));
const StatusPage = lazy(() => import("./pages/StatusPage"));
const SubscriptionPage = lazy(() => import("./pages/SubscriptionPage"));
const VerificationPage = lazy(() => import("./pages/VerificationPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const DataProtectionPage = lazy(() => import("./pages/DataProtection"));
const TermsOfUsePage = lazy(() => import("./pages/TermsOfUsePage"));
const HelpPage = lazy(() => import("./pages/HelpPage"));
const ResellerPage = lazy(() => import("./pages/ResellerPage"));

const App: React.FC = () => {
  const { userData } = useAuth();

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route path="/" element={<Landing />} />
              {/* <Route path="/signup" element={<SignupPage />} />
              <Route path="/signin" element={<SigninPage />} /> */}
              <Route path="/status" element={<StatusPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/data" element={<DataProtectionPage />} />
              <Route path="/terms" element={<TermsOfUsePage />} />
              <Route path="/faq" element={<HelpPage />} />
              <Route path="/reseller" element={<ResellerPage />} />
              <Route
                path="/signout"
                element={
                  <AuthenticatedRoute>
                    <SignoutPage />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/account/forgotpassword"
                element={<ForgotPasswordPage />}
              />
              <Route path="/account/verify" element={<VerificationPage />} />
              <Route
                path="/home"
                element={
                  <AuthenticatedRoute>
                    <HomePage />
                  </AuthenticatedRoute>
                }
              />
              {userData?.role === 0 && (
                <Route
                  path="/candidate/home"
                  element={
                    <AuthenticatedRoute>
                      <CandidateHome />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {userData?.role === 0 && (
                <Route
                  path="/candidate/subscription"
                  element={
                    <AuthenticatedRoute>
                      <SubscriptionPage />
                    </AuthenticatedRoute>
                  }
                />
              )}

              {userData?.role === 0 && (
                <Route
                  path="/candidate/competitors"
                  element={
                    <AuthenticatedRoute>
                      <CompetitorListPage />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {userData?.role === 0 && (
                <Route
                  path="/candidate/reports"
                  element={
                    <AuthenticatedRoute>
                      <CandidateReportsPage />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {userData?.role === 2 && (
                <Route
                  path="/partyagent/home"
                  element={
                    <AuthenticatedRoute>
                      <PartyAgentHome />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {[1, 2].includes(userData?.role || -1) && (
                <Route
                  path="/agent/home"
                  element={
                    <AuthenticatedRoute>
                      <AgentHome />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {userData?.role === 0 && (
                <Route
                  path="/candidate/agents"
                  element={
                    <AuthenticatedRoute>
                      <AgentListPage />
                    </AuthenticatedRoute>
                  }
                />
              )}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
