import { parseISO } from "date-fns";
import { PhoneNumberUtil as phoneUtil } from "google-libphonenumber";

export const safeParseInt = (data: any): number | undefined => {
  const result = parseInt(data);
  if (isNaN(result)) {
    return undefined;
  }
  return result;
};
export const safeParseNumber = (data: any): number => {
  return safeParseInt(data) || 0;
};

export const safeParseDate = (
  data: Date | undefined | string,
  isDateTime?: boolean
): string | undefined => {
  try {
    if (data) {
      const result = new Date(data);
      if (result) {
        return isDateTime
          ? `${result.toLocaleDateString()} ${result.toLocaleTimeString()}`
          : result.toLocaleDateString();
      }
      return data.toLocaleString();
    }
  } catch {
    //
  }

  return undefined;
};

export const isValidEmail = (email: string | undefined) => {
  if (email === undefined) {
    return false;
  }
  const emailFmt = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailFmt.test(email);
};

const phoneCheck = phoneUtil.getInstance();
export const isValidPhone = (phone: string | undefined) => {
  if (phone) {
    try {
      return phoneCheck.isValidNumberForRegion(
        phoneCheck.parse(phone, "KE"),
        "KE"
      );
    } catch {
      //dont care
    }
  }
  return false;
};

export const concatSpaceAndTrim = (data: (string | undefined)[]) => {
  const result = data.reduce((cumu, curr) => {
    return cumu?.concat(" ").concat(curr || "");
  });

  return result?.trim().replace("  ", " ") || "";
};

interface IPInfo {
  ipAddress?: string;
  location?: string;
}

export const getIPInfo = async (): Promise<IPInfo | undefined> => {
  try {
    const response = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
    const info = await response.text();
    const parts = info.split("\n");

    return {
      ipAddress: parts.find((p: string) => p.startsWith("ip"))?.split("=")[1],
      location: parts.find((p: string) => p.startsWith("loc"))?.split("=")[1],
    };
  } catch (e) {}
};

export const dateParse = (date: string | undefined) => {
  if (date) {
    return parseISO(date).toLocaleTimeString();
  }
  return "";
};

export const pct = (a: number, b: number) => {
  if (a === 0 || b === 0) return "";
  return ((a / b) * 100).toFixed(1) + "%";
};

export const handleTokenExpired = (response: any, navigator: any) => {
  if (response.status === 401) {
    navigator(
      `/signin?from=${window.location.pathname}${window.location.search}`
    );
  }
};

export const normalizePhone = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  if (phoneNumber.startsWith("0")) {
    return phoneNumber;
  }

  return phoneNumber.replace("254", "0");
};

export function uuid() {
  let d = new Date().getTime();
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
export const getToken = () => {
  return `Bearer ${sessionStorage.getItem("gmba.token")}`;
};
