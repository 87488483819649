import { useAuth } from "../../data/auth";
import { Navigate } from "react-router-dom";
export const AuthenticatedRoute: React.FC<{ children: any }> = ({
  children,
}) => {
  const { userData } = useAuth();
  const hasSession =
    userData && userData.isLoggedIn && userData.expiry > Date.now();
  if (!hasSession) {
    return (
      <Navigate
        to={{
          pathname: "/signin",
          search: `from=${window.location?.pathname}`,
        }}
      />
    );
  }

  return children;
};

export default AuthenticatedRoute;
