import { createTheme, Theme } from "@mui/material/styles";

export const appColors = {
  white: "#FFFFFF",
  black1: "#020F16",
  lightGreen: "#ECFCE5",
  darkgreen: "#198155",
  bgGray: "#E3E5E6",
  lightgray: "lightgray",
  warning: "orangeRed",
  inky: "#202325",
  luminous: "#e0ec58",
};

export const theme = createTheme({
  palette: {
    primary: {
      light: "#ECFCE5",
      main: "#198155",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  typography: {
    body1: {
      fontFamily: "IBM Plex Sans",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: "IBM Plex Sans",
        },
        listbox: {
          fontFamily: "IBM Plex Sans",
        },
        input: {
          fontFamily: "IBM Plex Sans",
        },
      },
    },
  },
});
